<template>
    <div class="container">
        <div class="header">
            <img src="../../assets/support/tt.png" alt="">
            <div class="bar">
                <div>
                    <img src="../../assets/contact/qrcode.jpg" alt="">
                    <p>微信公众号</p>
                </div>
                <div  @click="RouterTroubleshooter">
                    <img src="../../assets/support/gzpc@2x.png" alt="">
                    <div>
                        <p>故障排除指南</p>
                        <p>让我们排除一切故障</p>
                    </div>
                </div>
                <div>
                    <img src="../../assets/support/spjc@2x.png" alt="">
                    <div>
                        <p>视频教程</p>
                        <p>组装教程</p>
                    </div>
                    <div class="video" @click="isPlay = true">
                        <img src="../../assets/support/sp@2x.png" alt="">
                        <img src="../../assets/support/bf.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="title">
                客户服务
            </div>
            <div class="line"></div>
            <div class="en-title">
                Customer Service
            </div>
            <div class="box">
                <!-- <img src="../../assets/support/cjwtjd.png" alt="" @click="$router.push('faq')"> -->
                <img src="../../assets/support/cpwdhyhsc.png" alt="" @click="$router.push('/productDocument')">
            </div>
        </div>
        <video-player :isShow="isPlay" @closeHandler="isPlay=false"></video-player>
    </div>
</template>

<script>
import VideoPlayer from './VideoPlayer.vue'
// 在页面加载完成后执行
window.onload = function() {
  // 将滚动条置于顶部
  setTimeout(function() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100);
}
export default {
    components: { VideoPlayer },
   data() {
      return {
        isPlay: false,
        // 1011,1025,1047,1124
      }
   },
   methods:{
    // 跳转到故障排除指南
    RouterTroubleshooter(){
        this.$router.push('/Troubleshooter');
        this.$store.commit('changeTheme',false);
    }
   },
}
</script>

<style scoped>
.header {
    position: relative;
    width: 100%;
}

.header > img {
    width: 100%;
    height: 9rem;
}

.bar {
    width: calc(100vw - 1.38rem * 2);
    position: absolute;
    left: 1.38rem;
    bottom: 0.88rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.bar > div {
    height: 2.14rem;
    background: rgba(1,21,55,0.75);
}

.bar > div:nth-of-type(1) {
    width: 2.44rem;
    margin-right: .32rem;
}
.bar > div:nth-of-type(1) img {
    width: 1.24rem;
    height: 1.24rem;
    margin-top: .3rem;
    margin-left: .6rem;
}
.bar > div:nth-of-type(1) p {
    text-align: center;
    font-size: .18rem;
    line-height: .25rem;
}

.bar > div:nth-of-type(2) {
    width: 4.07rem;
    display: flex;
}
.bar > div:nth-of-type(2) img {
    width: .96rem;
    height: .96rem;
    margin-top: .59rem;
    margin-left: .6rem;
    margin-right: .23rem;
}
.bar > div:nth-of-type(2) p:nth-of-type(1), .bar > div:nth-of-type(3) p:nth-of-type(1) {
    margin-top: .7rem;
    font-size: .28rem;
    line-height: .4rem;
}
.bar > div:nth-of-type(2) p:nth-of-type(2), .bar > div:nth-of-type(3) p:nth-of-type(2) {
    font-size: .16rem;
    font-weight: 400;
    line-height: .4rem;
}

.bar > div:nth-of-type(3) {
    width: 7rem;
    display: flex;
}
.bar > div:nth-of-type(3) > img {
    width: .96rem;
    height: .96rem;
    margin-top: .59rem;
    margin-left: .6rem;
    margin-right: .23rem;
}

.video {
    width: 2.24rem;
    height: .96rem;
    margin-left: 1rem;
    margin-top: .59rem;
    position: relative;
}
.video img:nth-child(1) {
    width: 100%;
    height: 100%;
}
.video img:nth-child(2) {
    position: absolute;
    width: .19rem;
    height: .25rem;
    left: 1.02rem;
    top: .35rem;
}

.content {
    padding: 1rem 1.6rem;
}

 .title {
    font-size: .32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #272727;
    cursor: pointer;
}
.en-title {
    font-size: .26rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #909090;
    margin-bottom: .58rem;
    cursor: pointer;
}
.line {
    width: 2.5vw;
    height: 3px;
    background: #127BF2;
    border-radius: 2px;
    margin: 10px 0;
}

.box {
    display: flex;
    justify-content: space-around;
}
.box img{
    width: 10.66rem;
    height: 5.3rem;
}
</style>
